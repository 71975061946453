<template>
    <modal ref="modalCrearCaja" titulo="Añadir caja fuerte" tamano="modal-lg" no-aceptar adicional="Añadir" @adicional="crearCaja">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-6">
                    <ValidationProvider v-slot="{errors}" rules="required|max:100" name="nombre">
                        <p class="f-12 text-general pl-3 f-500">Nombre de la caja</p>
                        <el-input v-model="model.nombre" placeholder="Nombre" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required|numeric|max_value:65535" name="verificación">
                        <p class="f-12 text-general pl-3 f-500  mt-4">Tiempo máximo de verificación de saldo" en horas</p>
                        <el-input v-model="model.verificacion_saldo" placeholder="Verificación" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="descripción">
                        <p class="f-12 text-general pl-3 f-500">Descripción</p>
                        <el-input v-model="model.descripcion" placeholder="Descripción de la caja" type="textarea" :rows="5" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-6 mt">
                    <ValidationProvider v-slot="{errors}" rules="required" name="moneda">
                        <p class="f-12 text-general f-500 pl-3">Seleccionar moneda</p>
                        <el-select v-model="model.idm_moneda" filterable class="w-100">
                            <el-option
                            v-for="item in monedas"
                            :key="item.id"
                            :label="item.sigla"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <p class="f-12 text-general f-500 pl-3 mt-4">Seleccionar cedis</p>
                    <el-select v-model="id_cedis" filterable class="w-100" @change="addCedis">
                        <el-option
                        v-for="item in selectCedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        :disabled="item.disabled"
                        />
                    </el-select>
                    <span v-if="validarCedis" class="text-danger w-100 f-11">Selecciona al menos un cedis.</span>
                    <div v-for="(c,key) in cedisAgregados" :key="key" class="row mx-0 bg-light-f5 border align-items-center pl-2 mt-2 br-5 f-14" style="height:32px;">
                        {{ c.nombre }} <i class="icon-cancel ml-auto mr-2 text-general f-16 cr-pointer" @click="removerCedis(c.id)" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Cajas from "~/services/flujoDinero/cajas";

export default {
    data(){
        return{
            cedisAgregados: [],
            id_cedis: null,
            validarCedis: false,
            model: {
                id: null,
                nombre: '',
                verificacion_saldo: 0,
                descripcion: '',
                idm_moneda: null,
            }
        }
    },
    computed:{
        ...mapGetters({
            monedas:'cedis/monedas',
            id_moneda:'cedis/id_moneda',
        }),
        selectCedis(){
            return this.cedis_calculo.reduce((acc, el) => {
                if(el.id != 0){
                    el.disabled = false
                    acc.push(el)
                }
                return acc
            }, [])
        }
    },
    watch: {
        cedisAgregados(val){
            this.validarCedis = !val.length
        },
    },
    methods: {
        toggle(){
            this.$refs.modalCrearCaja.toggle();
        },
        async crearCaja(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!this.cedisAgregados.length) this.validarCedis = true
                if(!valid || !this.cedisAgregados.length ){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.cedis = this.cedisAgregados
                this.model.id_moneda = this.id_moneda


                const { data } = await Cajas.crearCaja(this.model)
                this.notificacion('Mensaje', data.message, 'success')
                this.$emit('crear', data.model)
                this.limpiar()
                this.$refs.modalCrearCaja.toggle();
            } catch (error){
                this.error_catch(error)
            }
        },
        addCedis(id){
            let c = this.selectCedis.find(o=>o.id === id)
            c.disabled = true
            this.cedisAgregados.push(c)
            this.id_cedis = null
        },
        removerCedis(id){
            this.selectCedis.find(o=>o.id === id).disabled = false
            let c = this.selectCedis.find(o=>o.id === id)
            this.cedisAgregados.splice(this.cedisAgregados.indexOf(c), 1)
        },
        limpiar(){
            this.model = {
                id: null,
                nombre: '',
                descripcion: '',
                verificacion_saldo: 0,
                idm_moneda: null,
            }
            this.selectCedis.reduce((acc, el) => {
                if(el.id != 0){
                    el.disabled = false
                    acc.push(el)
                }
                return acc
            }, [])
            this.validarCedis = false
            this.cedisAgregados = []
            this.$refs.validacion.reset()
        }
    }
}
</script>
